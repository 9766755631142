import { Component, OnInit, OnChanges, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { global_variables } from '../../../environments/global_variables';
import { HttpService } from '../../services/http.service';
import { DataService } from '../../services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import {  SensorModel } from '../sensor-type/sensor-type-info';

@Component({
   selector: 'app-sensor-type-info',
   templateUrl: './sensor-type-info.component.html',
   styleUrls: ['./sensor-type-info.component.scss'],
})
export class SensorTypeInfoComponent implements OnInit, OnChanges {
   nSelectedCategory: number;

   isPageLoading: boolean;
   isRowHeader: boolean;
   isHeaderShow: boolean;
   isRowShow: boolean;
   isDebug: boolean;
   selectedSensorType: Object;
   sensorTypes: Object[];
   sensorModels: [];
   categories: string[];
   selectedSensorModel: SensorModel = {
      modelName: 'Default Model',
      enabledModes: [],
      gasList: [],
      sensorTypeId: ''
   };

   selectedIndex: number = 0;
   sensorModelModes: string[];
   activeEdit: string = '';
   editable: boolean = false;
   newCreatemod: boolean = false;
   newmodel: SensorModel = {
      modelName: '',
      gasList: [],
      enabledModes: [],
      sensorTypeId: ''

   }
   sensorModelNames: any = [];
   updsensorModelNames: any = [];
   showMainwrapper: boolean = true;
   showTypeName: string;
   showGaseslist: any = [];
   selectedIds: any = [];
   errorMessage: string = '';
   errorMessageEach: string = '';
   disableCredde: boolean = false;
   TABLETYPE = ['header_type', 'header_row_type'];
   PARATYPE = ['option'];
   isInterval: boolean = false;
   @Input() strTypeKey: string;
   @Input() isComponent: boolean;


   constructor(
      private _activeRoute: ActivatedRoute,
      private _dataService: DataService,
      private _httpService: HttpService,
      public _snackBar: MatSnackBar,
      private _dialog: MatDialog,
      private _cdr: ChangeDetectorRef
   ) {
      this.nSelectedCategory = -1; /*0: status, 1:voc analytics, 2:debug*/
      this.isPageLoading = false;
      this.isRowHeader = false;
      this.isHeaderShow = false;
      this.isRowShow = false;
      this.isDebug = false;

      this.categories = global_variables['Categories'];
      this.sensorModelModes = global_variables['modelModes'];
   }

   ngOnInit() {
      if (!this.isComponent) {
         this._activeRoute.params.subscribe({
            next: (params) => {
               this.getSensorTypes(params['id']);
               this.getSensorModels(params['id']);
            },
            error: error => console.error('sensor-type-info.component.ts -> this._activeRoute.params.subscribe: ', error)
         });
      }

      this.checkAvailability();



   }

   ngOnChanges() {
      console.log('component', this.strTypeKey);
      if (this.isComponent) {
         // used as a component
         this.getSensorTypes(this.strTypeKey);
         this.getSensorModels(this.strTypeKey);
      }
   }

   getSensorTypes(strKey: string) {
      this._httpService.getAsList(`${environment.APIS.SENSORTYPES}/${strKey}`, 1).subscribe({
         next: (programs) => {
            this.sensorTypes = programs;
            this.isPageLoading = true;
            this.onChangeCategory(0);
         },
         error: error => {
            console.error('sensor-type-info.component.ts -> this._httpService.getAsList: ', error);
         }
      });
   }

   getSensorModels(strKey: string, index = 0) {
      this._httpService.getListByOrder(`${environment.APIS.SENSORMODELS}`, 'sensorTypeId', strKey).subscribe({
         next: (sensorModels) => {
            console.log('sensorModels', sensorModels);
            this.sensorModels = sensorModels;
            this.selectedSensorModel = sensorModels[index];
            this.errorMessage = '';
            this.errorMessageEach = '';
            this.newCreatemod = false;
            this.sensorModels.forEach(element => {
               this.sensorModelNames.push(element['modelName']);
            });
         },
         error: error => {
            console.error('sensor-type-info.component.ts -> this._httpService.getAsList: ', error);
         }
      });
   }

   changeModels(model = 0, edit: boolean = true) {
      this.newCreatemod = false;
      this.selectedSensorModel = this.sensorModels[model];
      this.selectedIndex = model;
      this.disableCredde = false;
      if (edit) {
         this.activeEdit = '';
      }
   }

   makeModelEditable(modelKey = '', index = 0) {
      this.activeEdit = modelKey;
      this.changeModels(index, false);
      this.editable = true;
      this.errorMessageEach = '';
      this.updsensorModelNames = [];
      this.disableCredde = true;
      this.sensorModels.forEach(element => {
         if (element['key'] !== modelKey) {
            this.updsensorModelNames.push(element['modelName']);
         }
      });
   }

   cancelEdit(ind = 0) {
      this.activeEdit = '';
      this.editable = false;
      this.disableCredde = false;
      this.getSensorModels(this.strTypeKey, ind);
   }

   resetError() {
      this.errorMessage = '';
      this.errorMessageEach = '';
   }

   cancelNewModel() {
      this.newmodel = {
         modelName: '',
         gasList: [],
         enabledModes: ['fast'],
         sensorTypeId: ''
      };
      this.newCreatemod = false;
      this.errorMessage = '';
      this.disableCredde = false;
      this.getSensorModels(this.strTypeKey, this.selectedIndex);
   }

   checkValues(value) {
      if (this.selectedSensorModel['gasList']) {
         if (this.selectedSensorModel['gasList'].includes(value)) {
            this.selectedSensorModel['gasList'] = this.selectedSensorModel['gasList'].filter(val => val !== value);
         } else {
            this.selectedSensorModel['gasList'].push(value);
         }
      } else {
         this.selectedSensorModel['gasList'] = [];
         this.selectedSensorModel['gasList'].push(value);
      }
   }

   checkNewValues(value) {
      if (this.newmodel['gasList']) {
         if (this.newmodel['gasList'].includes(value)) {
            this.newmodel['gasList'] = this.newmodel['gasList'].filter(val => val !== value);
         } else {
            this.newmodel['gasList'].push(value);
         }
      } else {
         this.newmodel['gasList'] = [];
         this.newmodel['gasList'].push(value);
      }
   }

   updateModel(index) {
      const sensorModObj = {
         gasList: this.selectedSensorModel['gasList'],
         modelName: this.selectedSensorModel['modelName'].trim(),
         enabledModes: this.selectedSensorModel['enabledModes'],
      };

      // Validate model name
      if (this.selectedSensorModel['modelName'].trim().length < 4) {
         this.errorMessageEach = 'Please enter a valid Model Name.';
      } else if (this.updsensorModelNames.includes(this.selectedSensorModel['modelName'].trim())) {
         this.errorMessageEach = 'This name is already used. Please choose another Model Name.';
      } else {
         console.log('Updating Model Object:', sensorModObj); // Debugging
         this.updateModelObject(sensorModObj, index); // Call the update function
      }
   }




   updateModelObject(objectVal, ind = 0) {
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORMODELS}/${this.selectedSensorModel['key']}`, objectVal)
         .then(res => {
            this._snackBar.open('Sensor Model updated successfully!', 'Success', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
            this.getSensorModels(this.strTypeKey, ind);
            this.activeEdit = '';
            this.editable = false;
            this.disableCredde = false;
         });
   }

   deleteModel(modelName, modelKey) {
      const config = {
         disableClose: true,
         data: {
            title: 'Delete',
            description: 'Are you sure to delete this Sensor Model - ' + modelName + '?',
         },
      };
      const dialogRef = this._dialog.open(ConfirmModalComponent, config);

      dialogRef.afterClosed().subscribe({
         next: (result) => {
            if (result) {
               const strSensorModelUrl = `${environment['APIS']['SENSORMODELS']}/${modelKey}`;
               this._httpService.deleteAsObject(strSensorModelUrl).then(
                  () => {
                     // Remove the model from sensorModelNames array
                     this.sensorModelNames = this.sensorModelNames.filter(name => name !== modelName);
                     this._snackBar.open('Sensor Model deleted successfully!', 'Success', {
                        duration: 3000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                     });
                     this.getSensorModels(this.strTypeKey, 0);
                  },
                  error => {
                     console.log('Fail to delete Sensor Model database: ' + strSensorModelUrl);
                     console.error(error);
                  }
               );
            }
         },
         error: error => console.error('sensor-type-info.component.ts -> onDelete: ', error)
      });

   }

   showSensorModelForm() {
      this.activeEdit = '';
      this.editable = false;
      this.newCreatemod = true;
      this.disableCredde = true;
      this.selectedSensorModel = {
         modelName: '',
         enabledModes: [''],
         gasList: [],
         sensorTypeId: ''
      };

   }


   createNewSensor() {
      this.newmodel['sensorTypeId'] = this.strTypeKey;
      this.newmodel['modelName'] = this.newmodel['modelName'].trim();
      this.errorMessage = '';

      if (this.newmodel['modelName'].trim().length < 4) {
         this.errorMessage = 'Please enter a valid Model Name.';
      } else if (this.sensorModelNames.includes(this.newmodel['modelName'].trim())) {
         this.errorMessage = 'This name is already used. Please change Model Name.';
      } else {

         console.log('Final Model Being Saved:', this.newmodel); // Debugging

         this._httpService
            .createAsList(environment.APIS.SENSORMODELS, this.newmodel)
            .then(res => {
               console.log(res);

               this._snackBar.open('New Sensor Model Created.', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });

               this.getSensorModels(this.strTypeKey, 0);
               this.newCreatemod = false;
               this.disableCredde = false;

               // Reset the form
               this.newmodel = {
                  modelName: '',
                  gasList: [],
                  enabledModes: ['fast'],
                  sensorTypeId: ''
               };
            })
            .catch(error => {
               console.log(error);

               this._snackBar.open('Internet or Permission Error.', 'Error', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            });
      }
   }



   checkAvailability() {
      const userType = this._dataService.getString('customer_id');

      if (userType && userType !== '') {
         // customer
         this.isDebug = false;
      } else {
         // staff
         this.isDebug = true;
      }
   }

   onChangeCategory(category: number) {
      this.nSelectedCategory = category;

      console.log('this.sensorTypes', this.sensorTypes);
      console.log('this.categories', this.categories);
      if (this.sensorTypes && this.sensorTypes.length) {
         const types = this.sensorTypes.filter(item => {
            if (item['key'] == 'typeName') {
               this.showTypeName = item['$value'];
            }
            if (item['key'] == 'vocAnalytics') {
               this.showGaseslist = item['rows'];
            }
            return item['key'] === this.categories[this.nSelectedCategory];
         });
         console.log('types', types);
         this.selectedSensorType = types[0];

         if (category == 3) {
            this.showMainwrapper = false;
         } else {
            this.showMainwrapper = true;


            if (this.selectedSensorType['tableType'] === this.TABLETYPE[1]) {
               this.isRowHeader = true;
            } else {
               this.isRowHeader = false;
            }

            if (this.selectedSensorType['heads']) {
               this.isHeaderShow = true;
            } else {
               this.isHeaderShow = false;
            }

            if (this.selectedSensorType['rows']) {
               this.isRowShow = true;
            } else {
               this.isRowShow = false;
            }
         }
      }
   }
}
