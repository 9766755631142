<div class="sensor-type-info-wrapper p-3" *ngIf="isPageLoading">
	<div class="mb-2" fxLayout="row" fxLayoutAlign="end center"><!--action wrapper-->
		<button mat-raised-button class="btn" [ngClass]="{'btn-primary': nSelectedCategory === 0}"
			(click)="onChangeCategory(0)">Status</button>
		<button mat-raised-button class="btn ml-2" [ngClass]="{'btn-primary': nSelectedCategory === 1}"
			(click)="onChangeCategory(1)">VOC Analytics</button>
		<button mat-raised-button class="btn ml-2" [ngClass]="{'btn-primary': nSelectedCategory ===2 }"
			(click)="onChangeCategory(2)">Debug</button>
		<button mat-raised-button class="btn ml-2" [ngClass]="{'btn-primary': nSelectedCategory ===3 }"
			(click)="onChangeCategory(3)">Models</button>
	</div><!--action wrapper-->


	<div class="row no-margin display-wrapper overflow-y" *ngIf="showMainwrapper;else subWrapper"><!--display wrapper-->
		<table class="table table-hover table-bordered" *ngIf="isRowHeader;else rowTypeEle">
			<thead *ngIf="!isHeaderShow; else hasHeaderEle">
				<tr>
					<th>Name</th>
					<th>Type</th>
				</tr>
			</thead>
			<ng-template #hasHeaderEle>
				<thead>
					<tr>
						<th *ngFor="let head of selectedSensorType.heads; let i = index;">
							{{ head.name + (i === 0 ? '(Name)' : '(Type)') }}
						</th>
					</tr>
				</thead>
			</ng-template>

			<tbody *ngIf="isRowShow && !isHeaderShow">
				<tr *ngFor="let row of selectedSensorType.rows">
					<td>{{ row.name }}</td>
					<td [ngClass]="{capitalize: row.valueType !== PARATYPE[0]}">
						{{ [row.valueType, row.defaultValue] | sensortypeDetail }}
					</td>
				</tr>
			</tbody>

			<tbody *ngIf="isRowShow && isHeaderShow">
				<tr *ngFor="let row of selectedSensorType.rows">
					<td>{{ row.name }}</td>
					<td *ngFor="let head of selectedSensorType.heads; let i = index;"
						[ngClass]="{'display-hide': i === 0, capitalize: row.valueType !== PARATYPE[0]}">
						{{ [row.valueType, row.defaultValue] | sensortypeDetail }}
					</td>
				</tr>
			</tbody>
		</table>

		<ng-template #rowTypeEle>
			<table class="table table-hover table-bordered" *ngIf="!isRowHeader">
				<thead>
					<tr>
						<th *ngFor="let head of selectedSensorType.heads">
							{{head.name}}
						</th>
					</tr>
				</thead>

				<tbody>
					<tr>
						<th *ngFor="let head of selectedSensorType.heads">
						</th>
					</tr>
				</tbody>
			</table>
		</ng-template>
	</div><!--display wrapper-->
	<ng-template #subWrapper>
		<div class="row no-margin display-wrapper overflow-y">
			<form class="w-full d-flex" #myForm="ngForm">
				<div class="col-sm-6 model-name-block">
					<span class="heading-style1">Models for {{showTypeName}}</span>
					<div class="model-section-box">
						<div *ngFor="let model of sensorModels; let index = index">
							<div class="d-flex justify-content-between mnot"
								[ngClass]="{'model-selected': model.key === selectedSensorModel.key}">
								<ng-container *ngIf="activeEdit === model.key">
									<mat-form-field>
										<input matInput type="text"
											style="margin: 5px 0px;color:#000;background-color:#fff;width:100%;"
											[(ngModel)]="model.modelName" [name]="model.modelName"
											(keydown)="resetError()" />
									</mat-form-field>
								</ng-container>
								<ng-container *ngIf="activeEdit !== model.key"><span
										(click)="changeModels(index)">{{model.modelName}}</span></ng-container>
								<div fxLayout fxLayoutAlign="center center">
									<button mat-icon-button class="model-nothingbutton btn-icon"
										(click)="makeModelEditable(model.key, index)" *ngIf="activeEdit !== model.key"
										[disabled]="disableCredde">
										<mat-icon color="primary">edit</mat-icon>
									</button>
									<button mat-icon-button class="model-nothingbutton btn-icon"
										(click)="deleteModel(model.modelName, model.key)"
										*ngIf="activeEdit !== model.key" [disabled]="disableCredde">
										<mat-icon color="warn">delete_outline</mat-icon>
									</button>

									<mat-icon class="pointer" style="padding: 5px 0px;" color="primary"
										(click)="updateModel(index)"
										*ngIf="activeEdit === model.key && editable === true">check</mat-icon>
									<mat-icon class="pointer" style="padding: 5px 0px;" color="warn"
										(click)="cancelEdit()"
										*ngIf="activeEdit === model.key && editable === true">close</mat-icon>
								</div>
							</div>
							<ng-container *ngIf="activeEdit === model.key">
								<div style="color:red;" *ngIf="errorMessageEach != ''">{{errorMessageEach}}</div>
							</ng-container>
						</div>
						<div class="model-edit-style">
							<ng-container *ngIf="newCreatemod">
								<mat-form-field>
									<input matInput #newModel type="text"
										style="color:#000;background-color:#BBDEFB; padding-left: 10px;"
										[(ngModel)]="newmodel.modelName" (keydown)="resetError()" name="newModel" />
								</mat-form-field>
								<div style="color:red;" *ngIf="errorMessage != ''">{{errorMessage}}</div>
							</ng-container>
						</div>
						<div class="row no-margin">
							<div class="action-button-area col-sm-12">
								<button color mat-mini-fab class="pull-left btn-icon add-icon"
									[disabled]="disableCredde" *ngIf="!newCreatemod" (click)="showSensorModelForm()">
									<mat-icon color="primary">add</mat-icon>
								</button>
								<button color mat-mini-fab class="pull-left btn-icon add-icon mr-2" *ngIf="newCreatemod"
									(click)="createNewSensor()">
									<mat-icon color="primary">check</mat-icon>
								</button>
								<button color mat-mini-fab class="pull-left btn-icon add-icon" *ngIf="newCreatemod"
									(click)="cancelNewModel()">
									<mat-icon color="primary">close</mat-icon>
								</button>
							</div>
						</div>
					</div>
				</div>
				<!-- TODO: HERE -->
				<div class="col-sm-6" style="height: 100%;">
					<div>
						<span class="heading-style1">Gases for
							{{ (newCreatemod) ? newmodel.modelName : selectedSensorModel.modelName }}</span>
						<div class="model-section-box gases">
							<ng-container *ngIf="activeEdit === '' && !newCreatemod">
								<div *ngFor="let gas of showGaseslist">
									<span
										*ngIf="selectedSensorModel['gasList'] && selectedSensorModel['gasList'].includes(gas.id)">
										{{ gas.name }}
									</span>
								</div>
							</ng-container>
							<ng-container *ngIf="activeEdit !== ''">
								<div *ngFor="let item of showGaseslist">
									<mat-checkbox [name]="selectedSensorModel.modelName + item.id" [value]="item.id"
										[checked]="selectedSensorModel['gasList'] && selectedSensorModel['gasList'].includes(item.id)"
										(click)="checkValues(item.id)">
										{{ item.name }}
									</mat-checkbox>
								</div>
							</ng-container>
							<ng-container *ngIf="newCreatemod">
								<div *ngFor="let item of showGaseslist">
									<mat-checkbox [name]="newmodel.modelName + item.id" [value]="item.id"
										[checked]="newmodel['gasList'] && newmodel['gasList'].includes(item.id)"
										(click)="checkNewValues(item.id)">
										{{ item.name }}
									</mat-checkbox>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="modes-for-model" style="height: 50%;">
						<span class="heading-style1">Modes for
							{{ (newCreatemod) ? newmodel.modelName : selectedSensorModel.modelName }}</span>
						<div class="model-section-box">
							<mat-radio-group [name]="'mode_' + selectedSensorModel.key"
								[(ngModel)]="selectedSensorModel.enabledModes[0]" *ngIf="!newCreatemod">
								<li *ngFor="let mode of sensorModelModes">
									<mat-radio-button [disabled]="activeEdit === ''" [value]="mode">
										{{ mode }}
									</mat-radio-button>
								</li>
							</mat-radio-group>
							<mat-radio-group [name]="'newModelMode_' + newmodel.modelName"
								[(ngModel)]="newmodel.enabledModes[0]" *ngIf="newCreatemod">
								<li *ngFor="let mode of sensorModelModes">
									<mat-radio-button [value]="mode">
										{{ mode }}
									</mat-radio-button>
								</li>
							</mat-radio-group>
						</div>
					


					</div>


				</div>

			</form>
		</div>
	</ng-template>
</div>