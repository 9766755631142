<div class="full-page-center">
  <mat-card class="sensor-dashboard container mt-4">
    <h2 class="sensor-title">Sensors Dashboard</h2>
    <mat-card>
      <mat-form-field class="search-field">
        <mat-label>Search Sensor</mat-label>
        <input matInput [(ngModel)]="searchText" placeholder="Enter sensor name">
      </mat-form-field>
      <table class="table table-bordered table-hover text-center modern-table">
        <thead class="table-dark">
          <tr>
            <th>Sensor Name</th>
            <th>Last Report</th>
            <th>Time Elapsed</th>
            <th>Enclosure Temp (°C)</th>
            <th>Enclosure Humidity (%)</th>
            <th>Ambient Temp (°C)</th>
            <th>Ambient Humidity (%)</th>
            <th>Ambient Pressure</th>
            <th>Avg Flow Rate (sccm)</th>
            <th>Avg Chip Temp (°C)</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let sensor of filteredSensors()" [ngClass]="{
            'table-success': extractMinutes(sensor.timeElapsed) < 120, 
            'table-warning': extractMinutes(sensor.timeElapsed) >= 120 && extractMinutes(sensor.timeElapsed) < 43200, 
            'table-warning': extractMinutes(sensor.timeElapsed) >= 43200
          }">
            <td>{{ sensor.sensorName || 'Unknown' }}</td>
            <td>{{ sensor.lastReportUtc }}</td>
            <td>
              <span class="badge" [ngClass]="{
                'bg-success': extractMinutes(sensor.timeElapsed) < 120,
                'bg-warning': extractMinutes(sensor.timeElapsed) >= 120 && extractMinutes(sensor.timeElapsed) < 43200,
                'bg-danger': extractMinutes(sensor.timeElapsed) >= 43200
              }">
                {{ sensor.timeElapsed }}
              </span>
            </td>
            <td>{{ sensor.measurements.enclosure.temperature ?? 'N/A' }}</td>
            <td>{{ sensor.measurements.enclosure.humidity ?? 'N/A' }}</td>
            <td>{{ sensor.measurements.ambient.temperature ?? 'N/A' }}</td>
            <td>{{ sensor.measurements.ambient.humidity ?? 'N/A' }}</td>
            <td>{{ sensor.measurements.ambient.pressure ?? 'N/A' }}</td>
            <td>{{ sensor.measurements.avgFlowRate ?? 'N/A' }}</td>
            <td>{{ sensor.measurements.avgChipTemp ?? 'N/A'}}</td>
          </tr>
        </tbody>
      </table>
    </mat-card>
  </mat-card>
</div>