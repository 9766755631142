import { ConfigModalModule } from './modals/config-modal/config-modal.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
//import { AgmCoreModule } from '@hudsontavares/agm-core';
import { GoogleMapsModule } from '@angular/google-maps';

import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import '../polyfills';

import { AuthService } from './services/auth.service';
import { PurehttpService } from './services/purehttp.service';
import { DataService } from './services/data.service';
import { EventService } from './services/event.service';
import { NotificationService } from './services/notification.service';
import { Authguard } from './services/authguard.service';
import { SpinnerService } from './components/spinner/spinner.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { UserModalComponent } from './modals/user-modal/user-modal.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { SensorTypeComponent } from './sensor/sensor-type/sensor-type.component';
import { SensorTypeCategoryComponent } from './sensor/sensor-type-category/sensor-type-category.component';
import { SensorTypeDetailComponent } from './sensor/sensor-type-detail/sensor-type-detail.component';
import { CommentModalComponent } from './modals/comment-modal/comment-modal.component';
import { CreateComponent } from './create/create.component';
import { CreateCustomerComponent } from './create/create-customer/create-customer.component';
import { CreateSensorComponent } from './create/create-sensor/create-sensor.component';
import { CreateZoneComponent } from './create/create-zone/create-zone.component';
import { VerifyComponent } from './verify/verify.component';
import { InviteModalComponent } from './modals/invite-modal/invite-modal.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { DailyTestModalComponent } from './modals/daily-test-modal/daily-test-modal.component';
import { AlertModalComponent } from './modals/alert-modal/alert-modal.component';
import { StorageManagerService } from './services/storage-manager.service';
import { ResetComponent } from './reset/reset.component';
import { SensorCalibrationComponent } from './sensor/sensor-calibration/sensor-calibration.component';
import { EditModalComponent } from './modals/edit-modal/edit-modal.component';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-alt-snotify';
import { RepeatConfirmModalComponent } from './modals/repeat-confirm-modal/repeat-confirm-modal.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RAW_DATA_PLOT_MODULE_CONFIG } from './raw-data-plot/raw-data-plot-data.service';

import { environment } from '../environments/environment';
import { MatModule } from './shared/modules/mat.module';
import { DetailsViewerModule } from './components/detail-viewer/details-viewer.module';
import { routes } from './routes';
import { CommentModule } from './components/comment/comment.module';
import { ConfigureComponent } from './configure/configure.component';
import { ZoneDetailComponent } from './zone/zone-detail/zone-detail.component';
import { SensorComponent } from './sensor/sensor.component';
import { SensorTopbarComponent } from './sensor/sensor-topbar/sensor-topbar.component';
import { SensorDetailComponent } from './sensor/sensor-detail/sensor-detail.component';
// import {PlotlyComponent} from './components/plotly/plotly.component';
import { RawTableComponent } from './sensor/raw-table/raw-table.component';
import { PeakTableComponent } from './sensor/peak-table/peak-table.component';
import { InputComponent } from './components/input/input.component';
import { MultipleAnalyticTableComponent } from './sensor/multiple-analytic-table/multiple-analytic-table.component';
import { AnalyticDataFilterPipe } from './filters/analytic-data-filter.pipe';
import { SystemParameterLogsComponent } from './sensor/system-parameter-logs/system-parameter-logs.component';
import { KeyTableComponent } from './components/key-table/key-table.component';
import { SensorTypeInfoModule } from './sensor/sensor-type-info/sensor-type-info.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NotificationModalComponent } from './modals/notification-modal/notification-modal.component';
import { EulaComponent } from './eula/eula.component';
import { WindPipe } from './pipes/wind.pipe';
import { WindDirectionPipe } from './pipes/wind-direction.pipe';
import { CalibrationStepsComponent } from './sensor/calibration-steps/calibration-steps.component';
import { StepOneComponent } from './sensor/calibration-steps/step-one/step-one.component';
import { StepTwoComponent } from './sensor/calibration-steps/step-two/step-two.component';
import { CalibrationConfigModalComponent } from './modals/calibration-config-modal/calibration-config-modal.component';
import { NoPermissionsComponent } from './sensor/calibration-steps/no-permissions/no-permissions.component';
import { CalibrationUpdateModalComponent } from './sensor/calibration-update-modal/calibration-update-modal.component';
import { EmailsWidgetComponent } from './modals/emails-widget/emails-widget.component';
import { CustomAlertTemplateModalComponent } from './modals/custom-alert-template-modal/custom-alert-template-modal.component';
import { SyncAlertsComponent } from './modals/sync-alerts/sync-alerts.component';
import { SetDaysComponent } from './sensor/sensor-detail/set-days/set-days.component';
import { HttpService } from './services/http.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NetworkPowerComponent } from './components/network-power/network-power.component';
import { WeatherComponent } from './components/weather/weather.component';
import { CleaningCycleComponent } from './components/cleaning-cycle/cleaning-cycle.component';
import { Test500Component } from './test500/test500.component';
import { SensorsDashboardComponent } from './sensors-dashboard/sensors-dashboard.component';
@NgModule({
   declarations: [
      AppComponent,
      LoginComponent,
      SpinnerComponent,
      TopBarComponent,
      UserModalComponent,
      ConfirmModalComponent,
      SensorTypeComponent,
      SensorTypeCategoryComponent,
      DashboardComponent,
      SensorTypeDetailComponent,
      CommentModalComponent,
      ConfigureComponent,
      CreateComponent,
      SensorComponent,
      SensorsDashboardComponent,
      RawTableComponent,
      SystemParameterLogsComponent,
      KeyTableComponent,
      InputComponent,
      MultipleAnalyticTableComponent,
      PeakTableComponent,
      CreateCustomerComponent,
      ZoneDetailComponent,
      CreateSensorComponent,
      CreateZoneComponent,
      VerifyComponent,
      InviteModalComponent,
      SignUpComponent,
      DailyTestModalComponent,
      AlertModalComponent,
      ResetComponent,
      SensorCalibrationComponent,
      EditModalComponent,
      NotificationModalComponent,
      EmailsWidgetComponent,
      RepeatConfirmModalComponent,
      ResetPasswordComponent,
      SensorTopbarComponent,
      SensorDetailComponent,
      AnalyticDataFilterPipe,
      EulaComponent,
      WindPipe,
      WindDirectionPipe,
      CalibrationStepsComponent,
      StepOneComponent,
      StepTwoComponent,
      CalibrationConfigModalComponent,
      NoPermissionsComponent,
      CalibrationUpdateModalComponent,
      CustomAlertTemplateModalComponent,
      SyncAlertsComponent,
      SetDaysComponent,
      NetworkPowerComponent,
      WeatherComponent,
      CleaningCycleComponent,
      Test500Component,
   ],
   imports: [
      BrowserModule,
      /* AgmCoreModule.forRoot({
         apiKey: environment['googleAPIKey'],
      }), */
      AngularFireModule.initializeApp(environment.firebase),
      AngularFirestoreModule,
      AngularFireAuthModule,
      AngularFireDatabaseModule,
      AngularFireStorageModule,

      MatModule,
      RouterModule.forRoot(routes, {}),
      GoogleMapsModule,
      BrowserAnimationsModule,
      FormsModule,
      ReactiveFormsModule,
      ColorPickerModule,
      FlexLayoutModule,
      SensorTypeInfoModule,
      HttpClientModule,
      SnotifyModule,
      CommentModule,
      DetailsViewerModule,
      ConfigModalModule,
   ],
   providers: [
      AuthService,
      DataService,
      MatDatepickerModule,
      EventService,
      SpinnerService,
      NotificationService,
      HttpService,
      HttpClientModule,
      Authguard,
      PurehttpService,
      StorageManagerService,
      {
         provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
         useValue: { subscriptSizing: 'dynamic' },
      },
      { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
      SnotifyService,
      {
         provide: RAW_DATA_PLOT_MODULE_CONFIG,
         useValue: {
            apiBaseUrl: 'https://api-dev.omniscent.com',
            rawDataEndpoint: '/data-raw',
            dataMinutesEndpoint: '/data-minutes',
            dataHoursEndpoint: '/data-hours',
            dataDayEndpoint: '/data-days',
            accessToken: '03d0c5e5-a5d7-4607-875a-0394826f6ac0',
            chemicalsEndpoint: '/chemicals',
         },
      },
   ],
   exports: [MatButtonModule, MatCheckboxModule],
   bootstrap: [AppComponent],
})
export class AppModule { }
