import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ZoneAPIParams } from './zone-data';
import { AuthService } from '../services/auth.service';
import { IZoneReportResponse } from '../zone/models/zone.model';

@Injectable()
export class ZoneService {
   public readonly BASE_URL = 'https://omni-api.omniscent.com';
   private readonly queryUrl = '/v4/query';
   private readonly weatherQueryUrl = '/v4/weather';
   private readonly updateUrl = '/v4/update';

   constructor(private httpService: HttpClient, private _authService: AuthService) { }

   public getQueryData(
      sensorKeys: Array<string>,
      customerId: string,
      zoneParams: ZoneAPIParams
   ): Observable<IZoneReportResponse | string> {
      const url =
         this.BASE_URL +
         this.queryUrl +
         '/sensors/' +
         `${encodeURIComponent('[') + sensorKeys + encodeURIComponent(']')}/read`;
      const headers = new HttpHeaders().set('omni-customer', customerId);

      let params = new HttpParams();

      Object.keys(zoneParams).forEach(key => {
         if (Array.isArray(zoneParams[key])) {
            params = params.set(key, `[${zoneParams[key].join(',')}]`);
            return;
         }
         params = params.set(key, zoneParams[key]);
      });

      params = params.set('userTz', Intl.DateTimeFormat().resolvedOptions().timeZone);

      return this.httpService.get<IZoneReportResponse>(url, { params, headers }).pipe(catchError(() => of('error')));
   }

   public getQueryStatusData(environmentModifier: string): Observable<object> {
      const url = `${this.BASE_URL}/v4/status/sensors?environment=${environmentModifier}`;
      return this.httpService.get(url);
  }
   public getWeatherData(sensorKey: string,
      customerId: string): Observable<object> {
         
         const url =
         this.BASE_URL +
         this.weatherQueryUrl +
         '/sensors/' +
         `${sensorKey}/read`;
      const headers = new HttpHeaders().set('omni-customer', customerId);
      return this.httpService.get(url, {headers})
   }


   public updateData(
      fileRecordId: string,
      customerId: string,
      comment: string
   ): Observable<any> {
      const url =
         this.BASE_URL +
         this.updateUrl +
         '/sensorFile/' +
         `${encodeURIComponent(fileRecordId)}`;
      const headers = new HttpHeaders().set('omni-customer', customerId);

      let object = {};
      object['comment'] = comment;

      return this.httpService.patch(url, object, { headers });
   }
}
